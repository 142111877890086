import React from "react";
import ImportCard from "./importCard.js";

function Home() {
  return (
    <div className='home-background'>
      <ImportCard />
    </div>
  );
}

export default Home;
